.g-modal-registerUser {
    .g-modal-dialog {
        max-width: rem-calc(570);
        margin-top: 2rem;
        @include respond-to(mobile) {
            max-width: 100%;
            width: 100%;
            margin: 0;
        }
    }

    .b-return-wrap {
        max-width: rem-calc(550);
        @include respond-to(mobile) {
            max-width: 100%;
        }
    }
}

a[data-locale^='zh_HK'] {
    span {
        width: rem-calc(111);
    }
}

@include respond-to(desktop) {
    .SG-align-formrows {
        display: inline-block;
        width: 48%;

        &.post {
            float: right;
        }
    }
}

// Style for customer-service book a session logo
.pt_customer-service {
    .customer-service-links {
        a {
            &.book-a-session {
                background-position: 0 46.165%;

                &:hover {
                    background-position: 0 99.055%;
                }
            }
        }
    }
}

.b-cart-content {
    .b-cartlineitem_attributes {
        .b-productcard_footer {
            margin-bottom: rem-calc(26);
        }
    }

    .b-productcard_total_price {
        .member-price-badge {
            @include respond-to(mobile) {
                margin-right: rem-calc(20);
            }
        }
    }

    .error-message.alert {
        font-size: rem-calc(12);
        line-height: 1.67;
        background: $grey2;
        padding: rem-calc(22) rem-calc(16) rem-calc(16);
        border-radius: rem-calc(4);
        margin-bottom: rem-calc(30);
        color: $red;

        .error-icon {
            @include icon(alert);
        }

        span,
        p {
            width: auto;
            display: inline-block;
            vertical-align: top;
        }

        p {
            @include respond-to(desktop) {
                width: 95%;
                padding-left: rem-calc(17);
            }
        }

        @include respond-to(mobile) {
            padding: rem-calc(16);
            margin-top: rem-calc(30);
        }
    }

    .b-product_availability-lowInventory {
        &.warn {
            color: $red;
            padding-top: rem-calc(12);
            font-weight: 400;
            @include font(main, rem-calc(14), rem-calc(20));
        }
    }
}

.contact-phone-error-message {
    .invalid-feedback {
        display: block;
    }
}

.phone-error-message-text {
    @include error_message;
    @include t-text-5;

    padding-left: rem-calc(16);
}

.contant-tooltip {
    .g-tooltip-text {
        z-index: 2;
    }
}

.owl-carousel {
    @media screen and (min-width: 1024px) {
        .owl-item.active {
            &:hover {
                z-index: 2 !important;
            }
        }

        .owl-item:first-child {
            .g-tooltip-text {
                left: -1rem;
                transform: none;

                &::after {
                    left: 1.25rem;
                }
            }
        }

        .owl-item:last-child {
            .g-tooltip-text {
                left: auto;
                transform: none;
                right: -1.125rem;

                &::after {
                    left: auto;
                    right: 1rem;
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .owl-item:first-child {
            .g-tooltip-text {
                left: 0;
                transform: none;

                &::after {
                    left: 1.25rem;
                }
            }
        }

        .owl-item:last-child {
            .g-tooltip-text {
                left: auto;
                transform: none;
                right: 0;

                &::after {
                    left: auto;
                    right: 1rem;
                }
            }
        }

        .b-promo-tooltip-content {
            .g-tooltip-arrow {
                transform: rotate(180deg);
                bottom: 3px;
            }
        }
    }

    .b-promo-tooltip-content {
        .g-tooltip-text {
            top: auto;
            bottom: 1.8rem;

            &::after {
                bottom: -0.9rem;
                border-color: #1d1d1d transparent transparent;
            }
        }
    }
}

.product-quickview {
    .b-product-quickview-prices {
        margin-bottom: 0;
    }

    .atome-marketing {
        margin-bottom: rem-calc(16);
    }
}

.b-product_info {
    .b-promo-tooltip-content-text {
        word-break: break-all;
    }
}

.b-promo-tooltip-content-text {
    word-break: break-all;
}

.personalize__tooltip.tooltip {
    &.b-promo-tooltip-content {
        .g-tooltip-arrow {
            bottom: -7px;
        }
    }
}

.l-header-section_top {
    .b-header-banner {
        @include respond-to(mobile) {
            overflow: hidden;
            margin-right: 0;
            padding-right: 0;
        }

        .promo-banner-slider {
            .promo-mobile-layout {
                .b-header-banner-mobile {
                    @include respond-to(mobile) {
                        text-align: center;
                        padding-top: rem-calc(8);
                        line-height: rem-calc(15);
                    }
                }
            }
        }
    }
}

html:lang(ko) {
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        word-break: keep-all;
    }

    .shipping-address-section {
        .shipping-address-option {
            div,
            span {
                word-break: break-word;
            }
        }
    }

    #address-page {
        .b-account-address_book-container {
            .js-address_book-option {
                div,
                span {
                    word-break: break-word;
                }
            }
        }
    }
}

.b-cart-not-ea-access-modal.show {
    .g-modal-dialog {
        max-width: 28.125rem;
        min-height: calc(100% - (2rem * 2));
        margin: 2rem auto;
        position: relative;
        display: flex;
        align-items: center;
        width: auto;
        height: auto;
        pointer-events: none;

        .g-modal-content {
            min-height: rem-calc(630);
            padding: rem-calc(40) 0 0;

            .early-access-modal-container {
                min-height: rem-calc(206);
            }

            .early-access-fallback-content {
                margin: rem-calc(50) rem-calc(8);

                .early-access-fallback-content-group {
                    margin: 0 rem-calc(24) rem-calc(18);

                    .ineligible-title {
                        @include font (main_bold, rem-calc(16), rem-calc(24));

                        color: $red;
                        letter-spacing: rem-calc(0.5);
                    }

                    .ineligible-subtitle {
                        @include font (main_bold, rem-calc(32), 1);

                        text-transform: uppercase;
                        letter-spacing: rem-calc(0.5);
                        padding: rem-calc(5) 0;
                    }

                    .ineligible-text {
                        @include font (main, rem-calc(14), rem-calc(20));
                    }
                }

                .buttons {
                    margin: rem-calc(50) rem-calc(8);

                    a {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.early-access-fallback-close {
    margin: 0 rem-calc(16);

    a {
        max-width: 100%;
    }
}

button#embeddedMessagingConversationButton {
    bottom: 75px;
}

iframe#embeddedMessagingFrame {
    bottom: 125px;
}

@media screen and (min-width: 1024px) {
    .b-checkout_product-summary {
        .product-summary-block {
            .image-member-badge {
                .b-header_minicart-item-image {
                    height: auto;
                }
            }
        }
    }
}

.points-container-mobile {
    @include respond-to(mobile) {
        display: block;
    }

    @include respond-to(desktop) {
        display: none;
    }
}

.points-container-desktop {
    @include respond-to(desktop) {
        display: block;
    }

    @include respond-to(mobile) {
        display: none;
    }
}

.order-summary_discount.order-summary-color-red,
.discount-summary.order-summary-color-red {
    span {
        color: $red;
    }
}

.estimated-points-summary {
    color: $green;
}

.b-order-confirmation_right .uar-points-estimate {
    display: none;
}
