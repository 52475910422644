.b-createaccount,
.initiate-mobile-auth {
    .g-checkbox {
        position: relative;
        margin-bottom: rem-calc(28);
        text-align: left;
        margin-top: rem-calc(20);

        @include respond-to(mobile) {
            margin-top: rem-calc(28);
        }

        .g-checkbox-label {
            display: inline-block;
            padding-top: 0;
            text-align: left;

            .required-consents {
                white-space: nowrap;
                color: $red;
                text-decoration: none;
            }
        }
    }
}

.emaillist-checkbox {
    margin-bottom: rem-calc(12);
}

#register {
    .naverBtn {
        margin-bottom: 0;
        display: block;
        width: fit-content;
    }

    .top-text {
        margin: rem-calc(19.2) 0 rem-calc(16);
    }
}

.g-modal-complete-account {
    .complete-account-modal-content {
        align-items: center;

        .registration {
            margin: rem-calc(16) 0 0 0;
            width: 100%;
        }

        .b-return-wrap {
            .g-checkbox {
                margin-bottom: rem-calc(24);
            }
        }

        .completed-subheader {
            @include respond-to(phones) {
                align-self: start;
            }
        }
    }

    .g-login-modal-header {
        padding-bottom: 0;

        &-completed {
            padding-bottom: rem-calc(16);
        }
    }

    &.show {
        @include respond-to(phones) {
            .g-modal-body {
                width: 100%;
            }
        }
    }
}

.complete-account-modal-content,
.memberson-validation-modal-content {
    .register-modal-text {
        display: flex;
        flex-direction: column;
        text-align: start;
        margin: 0 auto;

        @include respond-to(phones) {
            margin: 0.5rem 0;
            align-self: start;
        }

        .register-return-icon {
            text-align: start;
        }

        p {
            display: flex;
            align-items: start;
            line-height: 1.5;

            span {
                line-height: 1;
            }
        }
    }

    .b-account_form-column-mobile {
        &-gender {
            width: 49%;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .b-input_row {
        position: relative;
        margin-bottom: 1rem;

        &-country_code {
            margin-left: rem-calc(12);
        }

        &-guest {
            text-align: center;

            @include respond-to(mobile) {
                margin-bottom: 0;
            }

            a {
                text-decoration: none;
            }
        }

        .phone-number-format {
            font-size: rem-calc(12);
        }
    }

    .b-account_form-row-three {
        .b-account_form-column {
            @include respond-to(desktop) {
                flex-basis: 49%;
                margin-right: 0;
            }
        }
    }

    .continue-shopping-container {
        margin-top: rem-calc(24);
        max-width: unset;

        @include respond-to(desktop) {
            max-width: rem-calc(346);
            margin: rem-calc(32) auto 0;
        }
    }

    .g-checkbox-complete-profile {
        margin-top: rem-calc(24);
    }

    .b-registration-error,
    .b-memberson-error {
        margin-bottom: rem-calc(16);
    }

    .profile-completion-box {
        display: flex;
        align-items: center;
        padding: rem-calc(15);
        margin-bottom: rem-calc(24);
        background-color: $grey2;

        @include respond-to(mobile) {
            width: 100%;
        }

        .ua-profile-img {
            margin-right: rem-calc(10);
        }

        .profile-info {
            p {
                text-align: start;
                padding-bottom: 0;
            }
        }
    }

    .subheader-message {
        font-size: 14px;
        line-height: 20px;

        @include respond-to(no-phones) {
            text-align: center;
        }
    }
}

.memberson-validation-modal-content {
    margin-top: rem-calc(16);
}
